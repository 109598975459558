import { Box, Button } from '@mui/material';
import { themeColors } from "../utils/constants";
import ModalCloseIcon from '../../javascript/images/ModalCloseIcon.png'
import "../../stylesheets/chat_page.css"
import { useState, useEffect, useRef } from 'react';
import feedbackIncite from '../api/feedback'
import CircularProgress from '@mui/material/CircularProgress';

const ReportModal = ({ setShowReportModal }) => {
  const [opacity, setOpacity] = useState(0)
  const [feedback, setFeedback] = useState('')
  const [loadingState, setLoadingState] = useState(0)
  const modalRef = useRef(null);

  useEffect(() => {
    setOpacity(1.0)
  }, [])

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowReportModal(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowReportModal]);
  
  let classPlaceholderInput = 'feedback-input'

  return (
    <div className="modal modal-paywall" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
    }}>
      <div className="paywall-modal-content" ref={modalRef}>
        <Box sx={{position: 'absolute', top: '20px', left: '20px', '&:hover': {cursor: 'pointer'}}} onClick={() => setShowReportModal(false)}>
          <img src={ModalCloseIcon} style={{height: '16px', width: '16px'}}/> 
        </Box>
        <Box className="base-text" sx={{margin: '16px auto 0px auto', width: '84%', textAlign: 'left', fontWeight: '500', fontSize: '14px', lineHeight: '133%',}}>
          Let us know if there was an error in the response or provide general feedback.
        </Box>
          <Box sx={{margin: '24px auto 20px auto', width: '86%', position: 'relative', 
            height: '160px',
            }}>
            <textarea
              style={{
                fontSize: '13px',
                height: '100%',
                position: 'absolute',
                left: '0px', right: '0px',
                backgroundColor: '#ccc',
              }}
              className={classPlaceholderInput}
              value={feedback}
              onChange={e => {
                setFeedback(e.target.value)
              }}
              placeholder={'Message..'}
            />
          </Box>

        <Box sx={{width: '260px', margin: '0px auto', maxWidth: '86%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '20px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '160px',
            height: '40px',
            maxWidth: '100%',
            textAlign: 'center',
            '&.Mui-disabled': {
              backgroundColor: '#696969',
            },
          }} disabled={(loadingState == 2 || feedback == '')} onClick={() => {
            if (loadingState == 0){//feedback.length > 0) { // Still send feedback if they typed but decided to stay
              let modifiedFeedback = "** ERROR REPORT **\n"+feedback
              feedbackIncite(modifiedFeedback, "error report")
              setLoadingState(1)
              setTimeout(() => {
                setLoadingState(2)
              }, 1000)
              setTimeout(() => {
                setShowReportModal(false)
              }, 2000) 
            }
          }}>
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontSize: '14px',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '1px',
              color: '#F2F2F2',
            }}>
              { (loadingState == 0) &&
              'Submit'
              }
              { (loadingState == 1) &&
                <CircularProgress size={24} color="inherit" />
              }
              { (loadingState == 2) &&
              'Thank you!'
              }
            </Box>
          </Button>
        </Box>
      </div>
    </div>
  )
}

export { ReportModal }
