import { createConsumer } from "@rails/actioncable";
import { websocketUrl } from "../utils/constants";

// Define a function that establishes a WebSocket connection
//export const connectPriceSocket = (setQuestionOnDemand, current_session_id, subscriptionRef, setMessageThreads, showLoadingWaitingMessage, setShowLoadingWaitingMessage, useFullWidth, setUseFullWidth) => {
export const connectPriceSocket = (current_session_id, subscriptionRef, watchlistData, setWatchlistData, setAnimatedItems) => {

  const consumer = createConsumer(websocketUrl);
  const subscription = consumer.subscriptions.create(
  { channel: "WebsocketMessageChannel", current_session_id: current_session_id },
  {
    connected() {
    console.log("WATCHLIST: Connected to websocket channel");
    },
    disconnected() {
      console.log("Disconnected from websocket channel");
      // For example, you can call connectWebSocket() again to reconnect.
      // console.log("Attempting to reconnect...")
      // connectWebSocket(); // pontentially implemenet backoff strategy!!! (reconnect dealys)
    },
    rejected() {
      console.error("WATCHLIST: Connection rejected!!");
    },
    received(data) {
      console.log("Receiving Data:", data)
      const websocketData = data["websocket-data"]
      
      const priceData = websocketData["prices"]
      const pctChangeData = websocketData["pct-change"]

      if (!priceData) return // Ensure that this is the data with websocket-data.

      setWatchlistData((prevData) => {
        const updatedStocks = prevData.Stocks.map((stock) => {
          if (priceData[stock.id]) {
            if (stock.currentPrice != priceData[stock.id]) { // Only if price has changed
              setAnimatedItems((prev) => ({ ...prev, [stock.id]: (priceData[stock.id] > stock.currentPrice ? "green":"red" ) }));
            }
            const newPricePctChange1d = (pctChangeData[stock.id] == "") ? {} : {pricePctChange1d: pctChangeData[stock.id]}
            return { ...stock, currentPrice: priceData[stock.id], ...newPricePctChange1d };
          }
          return stock;
        });
        const updatedCryptos = prevData.Cryptos.map((crypto) => {
          if (priceData[crypto.id]) {
            if (crypto.currentPrice != priceData[crypto.id]) { // Only if price has changed
              setAnimatedItems((prev) => ({ ...prev, [crypto.id]: (priceData[crypto.id] > crypto.currentPrice ? "green":"red" ) }));
            }
            const newPricePctChange1d = (pctChangeData[crypto.id] == "") ? {} : {pricePctChange1d: pctChangeData[crypto.id]}
            return { ...crypto, currentPrice: priceData[crypto.id], ...newPricePctChange1d };
          }
          return crypto;
        });
        const updatedForex = prevData.Forex.map((forex) => {
          if (priceData[forex.id]) {
            if (forex.currentPrice != priceData[forex.id]) { // Only if price has changed
              setAnimatedItems((prev) => ({ ...prev, [forex.id]: (priceData[forex.id] > forex.currentPrice ? "green":"red" ) }));
            }
            const newPricePctChange1d = (pctChangeData[forex.id] == "") ? {} : {pricePctChange1d: pctChangeData[forex.id]}
            return { ...forex, currentPrice: priceData[forex.id], ...newPricePctChange1d };
          }
          return forex;
        });
        // const updatedIndex = prevData.Index.map((index) => {
        //   if (priceData[index.id]) {
        //     if (index.currentPrice != priceData[index.id]) { // Only if price has changed
        //       setAnimatedItems((prev) => ({ ...prev, [index.id]: (priceData[index.id] > index.currentPrice ? "green":"red" ) }));
        //     }
        //     const newPricePctChange1d = (pctChangeData[index.id] == "") ? {} : {pricePctChange1d: pctChangeData[index.id]}
        //     return { ...index, currentPrice: priceData[index.id], ...newPricePctChange1d };
        //   }
        //   return index;
        // });
        // Store the updated data temporarily
        const updatedData = {
          Stocks: updatedStocks,
          Cryptos: updatedCryptos,
          Forex: updatedForex,
          // Index: updatedIndex,
        };
        // We want flash to start before the data actually updates. Better UI effect.
        setTimeout(() => {
          setWatchlistData(() => updatedData); // Set the state after delay
        }, 300);
        // Return the previous state to avoid immediate update
        return prevData;
      });

    }
  });
  subscriptionRef.current = subscription
};


export const getPricingData = () => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        watchlistPricingData {
          id
          assetType
          name
          ticker
          exchangeCountry
          action
          change
          updatedAt
          currentPrice
          pricePctChange1d
          pricePctChange24hr
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
   console.log("Made Requestion to initiate connection.")
  })
  .catch(error => console.error('Error fetching data:', error));
};
