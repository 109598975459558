import { WATCHLIST_WEBSOCKET } from '../utils/constants'

export const getPricingData = (dataGroup=WATCHLIST_WEBSOCKET, ticker_id=null) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query:
      `{
        watchlistPricingData(dataGroup: "${dataGroup}", tickerId: "${ticker_id}") {
          id
          assetType
          name
          ticker
          exchangeCountry
          action
          change
          updatedAt
          currentPrice
          pricePctChange1d
          pricePctChange24hr
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => {
    return response.json()
  })
  .then(data => {
   console.log("Made Requestion to initiate connection.")
  })
  .catch(error => console.error('Error fetching data:', error));
};
