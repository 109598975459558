import { useRef, useEffect, useState, useCallback } from "react"
import { Box, Link } from "@mui/material"
import '../../stylesheets/index.css'
import '../../stylesheets/chat_area.css'
import '../../stylesheets/message.css'
import ScrollDown from "./Reusable/ScrollDown";
import ChatButton from "./Reusable/ChatButton";
import RegenButton from "./Reusable/RegenButton";
import ChatMessage from "./Chat/ChatMessage"
import ShowHistory from "./Chat/ShowHistory";
import get_message_date from "./Chat/MessageDate";
import { useNavigate } from "react-router-dom";
import { handleSharing } from "../utils/share";
import { getUserMessages } from '../api/message'
import { isDarkMode, themeColors } from '../utils/constants'
import { ShareModal, ShareButtonChat } from "../utils/share";
import PrintOutText from "./Chat/PrintOutText";
import {ThumbUp, ThumbDown} from '../utils/Thumbs';
import { askShareMessage } from '../utils/constants'
import { SignInApple, SignInGoogle } from "./NewAccountPage"

const OrbAnimationDark = 'https://assets.inciteai.com/images/OrbAnimationDark.gif'
const OrbAnimationLight = 'https://assets.inciteai.com/images/OrbAnimationLight.gif'

const ChatBox = (props) => {
  const { 
    messageThreads,
    experienceMessageThreads,
    onQuestionSubmit,
    loadingResponse, 
    preventScroll, setPreventScroll,
    userData, setUserData,
    appProps,
    chatRef,
    chatContainerRef,
    chatMessagesContainerRef,
    showScrollDown, setShowScrollDown,
    hasShownHistory,
    regenButtonRecommendations,
    preventQuestionInput, setPreventQuestionInput,
    showLoadingWaitingMessage, setShowLoadingWaitingMessage,
    useFullWidth,
    // showNewUserMessage,
    isSecondaryExperience,
    secondaryExperienceType,
    isNewUUID,
    loadingUserData,
    setIsSecondaryExperience,
    showSignInButtons,
    questionOnDemand,
    setQuestionOnDemand,
  } = props

  // console.log("experienceMessageThreads", experienceMessageThreads)

  const { 
    responseFlow,
    setCurrentMessages,
    setMessageThreads,
    showChatShare, setShowChatShare,
    loadingUserMessages, setLoadingUserMessages,
  } = appProps

  const navigate = useNavigate()

  const [transitionFlowTextAmount, setTransitionFlowTextAmount] = useState(0)
  const [transitionFlowButtonsAmount, setTransitionFlowButtonsAmount] = useState(0)
  const [transitionFlowRegenAmount, setTransitionFlowRegenAmount] = useState(0)
  const [testVal, setTestVal] = useState(null)
  const chatMessageRef = useRef()
  let userHasHistory = true//userData.userHasHistory
  const [loadingShowHistory, setLoadingShowHistory] = useState(true)//useState(true)
  const [modalVisible, setModalVisible] = useState(false);
  const [showInitialMesageFlow, setShowInitialMesageFlow] = useState(false)
  const [displayChatBox, setDisplayChatBox] = useState(true)//useState(false)
  const [showButon1, setShowButon1] = useState(0)
  const [showButon2, setShowButon2] = useState(0)
  const [showMore, setShowMore] = useState(0)
  const [showOrb, setShowOrb] = useState(0)
  const [hasRefreshedButtons, setHasRefreshedButtons] = useState(false)
  const [canSeeWhy, setCanSeeWhy] = useState(0)
  const [isGifLoaded, setIsGifLoaded] = useState(false);
  const [isSelected, setIsSelected] = useState("")
  const [allowShowGif, setAllowShowGif] = useState(true);
  const isGifLoadedRef = useRef(isGifLoaded);
  const [errors, setErrors] = useState([])
  const [messageThreadLoaded, setMessageThreadLoaded] = useState(false)
  const [needsHistory, setNeedsHistory] = useState(null)

  let newUserQuestion1
  let newUserQuestion2
  if (secondaryExperienceType == "new-user") {
    //newUserQuestion1 = "Buy Apple?"
    //newUserQuestion2 = "Buy more Bitcoin?"
    newUserQuestion1 = "Best Tech Stock?"
    newUserQuestion2 = "Crypto To Buy?"
  } else if (secondaryExperienceType == "ask-share") {
    newUserQuestion1 = "Share Incite App"
    newUserQuestion2 = "Continue using Incite"
  }

  const handleRegenerateSubmit = async (question=null) => {
    if (showSignInButtons) {
      navigate('/register') 
    }

    setTransitionFlowButtonsAmount(0.0)
    setTransitionFlowRegenAmount(0.0)

    const questions_prepopulated = await regenButtonRecommendations(question)
    
    // Allow new buttons instead of default ones (only relevent for new users)
    setHasRefreshedButtons(true)

    setTimeout(() => {
    setTransitionFlowButtonsAmount(.99)
    setTransitionFlowRegenAmount(.99)
    }, 150)
    // dispatch to server with these new questions
    // server will update table to "attach" new questions
    // to that last chat message. It will update the row 
    
    if (chatMessageRef.current) {
      // chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  // Make sure it scrolls to the bottom after secondary experience!
  useEffect(() => {
    if (chatMessageRef.current && isSecondaryExperience == false) {
      setTimeout(() => { // delay for ref to be fully loaded
        chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }, [isSecondaryExperience])

  const requestShowHistory = () => {
    const isLoggedIn = !!userData.user_id
    if (!isLoggedIn) {
      navigate('/register')
    } else {
      setPreventScroll(true)
      getMessageHistory(appProps.setHasShownHistory, false, setLoadingUserMessages)
      setTimeout(() => {
        setPreventScroll(false)
      }, 1000)
    }
  }

  const isLoggedIn = !!userData.user_id
  // useEffect(() => {
  //   if (!isLoggedIn && isProduction) {
  //     const cookies = new Cookies();
  //     let hasAcceptedTerms = cookies.get('hasAcceptedTerms')
  //     if (!hasAcceptedTerms) {
  //       window.location.replace('https://inciteai.com')
  //     }
  //   }
  // }, [isLoggedIn])


  useEffect(() => {
    if (userData.uuid) {
      // console.log("-------------- FIRST PAGE LOAD!!!!! ----------", userData)
      // Basically once it loads in, since the quetions are given on the frontend,
      // we have to pass them to the backend for it to "attach" them to the last question
      // And asssess the tickers so that if they click a button it will be quick even on first one!
    }
  }, [userData.uuid])

  useEffect(() => {
    if (userData && userData.uuid) {
      if (Object.keys(userData).length){//} && (isLoggedIn || !userData.uuidHasAccount)) {
        setLoadingShowHistory(false)
        setNeedsHistory(true)
        getMessageHistory(appProps.setHasShownHistory, true, setLoadingUserMessages)
      } else {
        setNeedsHistory(false)
      }
    }
  }, [userData && userData.uuid]) // So this only fires once, and only when the user data is there (for pre account or user account)

  useEffect(() => {
    if (chatMessageRef.current && !loadingResponse && !preventScroll) {
      chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageThreads])


  useEffect(() => {
    if (!loadingResponse) {
      if (messageThreads.length > 1 && messageThreads[messageThreads.length-2].message != "Explain why?") {
        setCanSeeWhy(.99)
      } else {
        setCanSeeWhy(0)
      }
    }
  }, [messageThreads, loadingResponse])

  useEffect(() => {
    if (isSelected != "") {
      setIsSelected("")
    }
  }, [loadingResponse])

  const executeScroll = () => {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleScroll = useCallback(e => {
    // on some chrome browser cases, this value was still greater by 0.5 even at the bottom; so added a -1
    const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
    const pctBottom = e.target.scrollTop/(e.target.scrollHeight - e.target.clientHeight)
    if (testVal != true) {
      setTestVal(true)
    }
    if (bottom ||  e.target.scrollHeight < 2*e.target.clientHeight) { 
      setShowScrollDown(false)
    } else {
      if (!showScrollDown && pctBottom < .90) { 
        setShowScrollDown(true) 
        }
    }
  }, [])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  let countSinceShowMessageTime = 0

  useEffect(() => {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [responseFlow.review])

  // Use effect for line and next text
  useEffect(() => {
    if (responseFlow.text) {
      setTransitionFlowTextAmount(0.99)
      if (chatMessageRef.current) {
        chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setTransitionFlowTextAmount(0)
    }
  }, [responseFlow.text])

  useEffect(() => {
    if (responseFlow.question1[0]) {
      setTransitionFlowButtonsAmount(0.99)
      if (chatMessageRef.current) {
        chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setTransitionFlowButtonsAmount(0)
    }
  }, [responseFlow.question1[0]])

  useEffect(() => {
    if (responseFlow.regen) {
      setTransitionFlowRegenAmount(.99)
      if (chatMessageRef.current) {
        chatMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setTransitionFlowRegenAmount(0)
    }
  }, [responseFlow.regen])

  const getMessageHistory = (setHasShownHistory, firstLoad, setLoadingUserMessages) => {
    setLoadingUserMessages(true)
    let lastMessageDate = ""
    for (const m of messageThreads) {
      if (typeof m.createdAt === 'string') {
        lastMessageDate = m.createdAt
        break;
      }
    }
    let responseMessages = getUserMessages(lastMessageDate)
    Promise.all([responseMessages]).then(values => {
      let messages = values[0].messages
      if (!messages) { 
        setHasShownHistory(true)
        setLoadingUserMessages(false)
        return
      }
      let responseRecs = []
      setMessageThreads(messageThreads => 
        [messageThreads[0]].concat(messages.concat([...messageThreads].slice(1)))
      )
      setCurrentMessages(
        messages.concat(responseRecs.map(r => 
          ({
            sender: 'human',
            isButton: 'true',
            message: r
          })))
        )
      // if less than a full amount of messages retured; remove show history button
      if (messages.length < 6){  // NOTE! THIS VALUE MUST EQUAL "number_messages_return" defined in query_type.rb messsages endpoint!!
        setHasShownHistory(true)
      }
      setLoadingUserMessages(false)
    })
  }

  useEffect(() => {
    if (!loadingUserData) { // ensure no longer loading user state before rendering chat box
      setShowInitialMesageFlow(true)
      if (!isLoggedIn) { // if user is not logged in; can render immediately
        setDisplayChatBox(true)
      }
    }
  }, [loadingUserData, isLoggedIn])

  useEffect(() => {
    if (isSecondaryExperience != null) {
      if (isSecondaryExperience) {
        setShowButon1(0)
        setShowButon2(0)
        if (isDarkMode) {
          setTimeout(() => setShowButon1(.99), 2666)//4000)
          setTimeout(() => setShowButon2(.99), 3000)//4500)
          setTimeout(() => setShowMore(.99), 2533)//3800)
          setTimeout(() => setShowOrb(.99), 1933)//2900)
          setTimeout(() => setShowOrb(0), 7333)//11000)
        } else {
          setTimeout(() => setShowButon1(.99), 2066)//3100)
          setTimeout(() => setShowButon2(.99), 2400)//3600)
          setTimeout(() => setShowMore(.99), 1933)//2900)
        }
        
      } else {
        setShowButon1(.99)
        setShowButon2(.99)
        setShowMore(.99)
      }
    }
  }, [isSecondaryExperience])

  useEffect(() => {
    if (isDarkMode) {
      const gifToLoad = isDarkMode ? OrbAnimationDark : OrbAnimationLight;
      const gifImage = new Image();
      gifImage.onload = () => {
        setIsGifLoaded(true);
        isGifLoadedRef.current = true;
      };
      setTimeout(() => {
        if (!isGifLoadedRef.current) {
          setAllowShowGif(false)
        }
      }, 2500) // Estimated time to start
      gifImage.src = gifToLoad;
    }
  }, [isDarkMode]);  // This useEffect will run whenever isDarkMode changes

  useEffect(() => {
    if (needsHistory == true) {
      if (messageThreads && messageThreads.length > 1) { // must have rendered more than just starting message.
        setMessageThreadLoaded(true)
      }
    } else if (needsHistory == false) {
      setMessageThreadLoaded(true) // no messages to low, allow render
    }
    // setTimeout(() => {
    //   setMessageThreadLoaded(true)
    // }, 800) // Even 500ms is not long enough for message threads to load.
  }, [messageThreads, needsHistory])

  return (
    <Box className="chatbox-div-container1" sx={{backgroundColor: themeColors.chatBoxColor}}>
    <Box className="chatbox-div-container3" sx={{backgroundColor: themeColors.chatBoxColor}} ref={chatRef}>
      {showInitialMesageFlow &&
      <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}> 
        <Box className="chatbox-scroll-container" sx={{backgroundColor: themeColors.chatBoxColor}} ref={chatContainerRef}>
          <Box className="inner-chatbox-scroll-container"  sx={{visibility: displayChatBox ? 'unset' : 'hidden', backgroundColor: themeColors.chatBoxColor}}ref={chatMessagesContainerRef}> 
            {/* <Box sx={{paddingBottom: '30px', maxWidth: '94%', margin: 'auto', position: 'relative'}}> */}
            <Box sx={{paddingBottom: '30px', maxWidth: '100%', margin: 'auto', position: 'relative'}}>
              { (!hasShownHistory && userHasHistory && !loadingShowHistory && !isSecondaryExperience) &&
                <ShowHistory appProps={appProps} requestShowHistory={requestShowHistory}/>  
              }
              { (messageThreadLoaded || isSecondaryExperience) && messageThreads.map((mt, idx) =>
              {
                if (idx == 0 && !isSecondaryExperience && messageThreads[idx].message == "") {return <></>}
                let marginVal
                if (idx == 0 || messageThreads[idx].sender == messageThreads[idx-1].sender){// || messageThreads[idx].sender == 'bot' || messageThreads[idx].isButton) {
                  marginVal = '8px'
                } else if (messageThreads[idx].isButton) {
                  marginVal = '17px'
                } else {
                  marginVal = '17px'
                }
                let isLastMessage = idx == messageThreads.length-1
                const { date_value, show_message_time } = get_message_date(messageThreads, countSinceShowMessageTime, idx)
                let styles = {}
                if (idx == 0) {
                  styles = { marginTop: '12px', marginBottom: '16px' }
                }
                if (idx == 0 && isNewUUID && !isSecondaryExperience) {
                  return <></>
                }
                return (
                  <Box key={`message-${idx}`}>
                    { !(idx == 0 || isSecondaryExperience) &&
                      <>
                        { ((show_message_time && idx > 2) || idx == 0) &&
                          <Box key={`message1-${idx}`} className="chatbox-time" sx={{
                            ...styles,
                            color: isDarkMode ? '#606773' : '#9BABC2'
                            }}>
                            {date_value}
                          </Box>
                        }
                      </>
                    }

                    { (idx == 0 && isSecondaryExperience && experienceMessageThreads[0]) &&
                      <>
                        { (secondaryExperienceType == "new-user") ?
                          <PrintOutText showDownArrowIcon={true} message={experienceMessageThreads[0].message} lineSpacing={[303, 250, 114]}/>
                        :
                          <PrintOutText showDownArrowIcon={true} message={experienceMessageThreads[0].message} lineSpacing={[329, 233, 100]}/>
                        }
                      </>
                    }
                    
                    { !isSecondaryExperience && 
                    <Box key={`message2-${idx}`} sx={{marginTop: marginVal, display: 'flex', flexDirection: 'column', position: 'relative' }} 
                    // ref={isLastMessage ? chatMessageRef : null}
                    >
                      <ChatMessage
                        idx={idx}
                        messageThread={mt}
                        loadingResponse={loadingResponse}
                        isLastMessage={isLastMessage}
                        showLoadingWaitingMessage={showLoadingWaitingMessage}
                        setShowLoadingWaitingMessage={setShowLoadingWaitingMessage}
                        useFullWidth={useFullWidth}
                        setModalVisible={setModalVisible} setShowChatShare={setShowChatShare}
                        questionOnDemand={questionOnDemand}
                        setQuestionOnDemand={setQuestionOnDemand}
                        preventQuestionInput={preventQuestionInput}
                        setPreventQuestionInput={setPreventQuestionInput}
                        onQuestionSubmit={onQuestionSubmit}
                        secondaryExperienceType={secondaryExperienceType}
                        appProps={appProps}
                        isLoggedIn={isLoggedIn}
                      />
                    </Box>
                    }
                  </Box>
                )}
                )
              }

              { (!isSecondaryExperience && !showChatShare && !loadingResponse) && 
                <Box sx={{
                  marginTop: '24px',
                  marginBottom: '24px',
                  width: '100%', display: 'flex',
                  alignItems: 'center', justifyContent: 'center',
                }}>
                  {/* <Box sx={{textAlign: 'right'}}>
                    <Link sx={{
                      '&:hover': {cursor: 'pointer', textDecoration: 'underline'},
                      fontFamily: 'Montserrat',
                      textDecoration: 'none',
                      fontSize: '14px',
                      fontWeight: 600,
                      background: isDarkMode ? "linear-gradient(180deg, #69CB60 0%, rgba(0, 194, 255, 0.69) 100%)" : "linear-gradient(180deg, #2971D7 0%, rgba(28, 112, 173, 0.69) 100%)",
                      WebkitTextFillColor: 'transparent',
                      WebkitBackgroundClip: 'text',
                    }} onClick={() => {
                      navigate('/feedback')
                      // handleSharing(setModalVisible, setShowChatShare, true)
                    }}>Feedback</Link>
                  </Box> */}
                </Box>
              }
          
              {(!isSecondaryExperience) ? 
              <Box sx={{}}>
              </Box>
              :
              <Box sx={{marginBottom: isDarkMode ? '72px' : '20px'}}></Box>
              }

              { showChatShare &&
                <>
                <ShareModal modalVisible={modalVisible} setModalVisible={setModalVisible}/>
                <ShareButtonChat setShowChatShare={setShowChatShare} transitionFlowButtonsAmount={transitionFlowButtonsAmount} setModalVisible={setModalVisible}/>
                </>
              }

              {/* Also require dark mode to show any isGifLoaded */}
              { (showOrb !== 0 && isSecondaryExperience && allowShowGif && isDarkMode) &&
                <Box sx={{
                  top: '162px', zIndex: 0, position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                  <img 
                    src={isGifLoaded ? (isDarkMode ? OrbAnimationDark : OrbAnimationLight) : null} 
                    style={{width: '220px', height:'220px'}}
                    alt="Orb Animation"
                  />
                </Box>
              }

            { showSignInButtons ?
              <Box sx={{marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <SignInApple userData={userData} setUserData={setUserData} setErrors={setErrors} onChatPage={true}/>
                <SignInGoogle userData={userData} setUserData={setUserData} setErrors={setErrors} onChatPage={true}/>
              </Box>
            :
              <>
                { !showChatShare &&
                <Box sx={{marginTop: '4px', opacity: showButon1, transition: 'opacity 1s ease'}}>
                <ChatButton 
                  questionNumber={1}
                  //q={(isSecondaryExperience && !hasRefreshedButtons) ? newUserQuestion1 : responseFlow.question1[1]} 
                  q={(isSecondaryExperience) ? newUserQuestion1 : responseFlow.question1[1]} 
                  onQuestionSubmit={onQuestionSubmit} 
                  transitionFlowButtonsAmount={transitionFlowButtonsAmount}
                  preventQuestionInput={preventQuestionInput}
                  setPreventQuestionInput={setPreventQuestionInput}
                  //isSecondaryExperience={(isSecondaryExperience && !hasRefreshedButtons)}
                  isSecondaryExperience={isSecondaryExperience}
                  secondaryExperienceType={secondaryExperienceType}
                  userData={userData} setUserData={setUserData}
                  setIsSecondaryExperience={setIsSecondaryExperience}
                  setModalVisible={setModalVisible} setShowChatShare={setShowChatShare}
                  handleRegenerateSubmit={handleRegenerateSubmit}
                />
                </Box>
                }
                { !showChatShare &&
                <Box ref={chatMessageRef} className={(isSecondaryExperience && secondaryExperienceType == "ask-share") ? "bottom-button-placement":""} sx={{marginTop: '4px', opacity: showButon2, transition: 'opacity 1s ease'}}>
                <ChatButton
                  questionNumber={2}
                  // q={(isSecondaryExperience && !hasRefreshedButtons) ? newUserQuestion2 : responseFlow.question2[1]}
                  q={(isSecondaryExperience) ? newUserQuestion2 : responseFlow.question2[1]}
                  onQuestionSubmit={onQuestionSubmit} 
                  transitionFlowButtonsAmount={transitionFlowButtonsAmount}
                  preventQuestionInput={preventQuestionInput}
                  setPreventQuestionInput={setPreventQuestionInput}
                  // isSecondaryExperience={(isSecondaryExperience && !hasRefreshedButtons)}
                  isSecondaryExperience={isSecondaryExperience}
                  secondaryExperienceType={secondaryExperienceType}
                  userData={userData} setUserData={setUserData}
                  setIsSecondaryExperience={setIsSecondaryExperience}
                  setModalVisible={setModalVisible} setShowChatShare={setShowChatShare}
                  handleRegenerateSubmit={handleRegenerateSubmit}
                />
                </Box>
                }

              </>
            }
            
            {/* { (!showChatShare && !isSecondaryExperience) &&
            <Box
            ref={chatMessageRef} // apply ref to regen button
            >
              <RegenButton transitionFlowRegenAmount={transitionFlowRegenAmount} showMore={showMore} userData={userData} preventQuestionInput={preventQuestionInput} handleRegenerateSubmit={handleRegenerateSubmit}/>
              </Box>
            } */}

            { showScrollDown &&
              <ScrollDown executeScroll={executeScroll}/>
            }
          </Box>
          </Box>
        </Box>
      </Box>
      }
    </Box>
    </Box>
  )
}


export default ChatBox
       
